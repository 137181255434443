<template>
    <div @click="toggleMenu" ref="burgerRef" class="burger-menu">
        <FontAwesomeIcon icon="bars" fixed-width/>
    </div>
    <div v-if="menuDeployed" class="blured-back"></div>
    <div :class="{deployed: menuDeployed}" ref="menuRef" class="deployed-burger-menu">
        <FontAwesomeIcon icon="window-close" fixed-width @click="toggleMenu"/>
        <ul class="section-list">
            <li>
                <router-link :to="{ name: 'home', params: { lang: $route.params.lang}}" @click="toggleMenu">{{$route.params.lang=='fr'?'SIRA':'SIRA'}}</router-link>
                <ul>
                    <!-- <li>
                        <router-link :to="{ name: 'contacts', params: { lang: $route.params.lang}}" @click="toggleMenu">{{$route.params.lang=='fr'?'A propos':'About us'}}</router-link>
                    </li> -->
                    <li>
                        <router-link :to="{ name: 'home_detail', params: { lang: $route.params.lang, id: 'contacts' }}" @click="toggleMenu">{{$route.params.lang=='fr'?'Contact':'Contact'}}</router-link>
                
                    </li>
                </ul>
            </li>
            <li>
                <router-link :to="{ name: 'studio_hub', params: { lang: $route.params.lang}}" @click="toggleMenu">{{$route.params.lang=='fr'?'Studios':'Studios'}}</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'consulting_hub', params: { lang: $route.params.lang}}" @click="toggleMenu">{{$route.params.lang=='fr'?'Consulting':'Consulting'}}</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'galerie_hub', params: { lang: $route.params.lang}}" @click="toggleMenu">{{$route.params.lang=='fr'?'Galeries':'Galleries'}}</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'radio_hub', params: { lang: $route.params.lang}}" @click="toggleMenu">{{$route.params.lang=='fr'?'News':'News'}}</router-link>
            </li>
        </ul>
        <ul class="burger-footer">
            <li class="socials"><a href="https://www.instagram.com/sira.sira.sira.sira/" target="_blank"><FontAwesomeIcon :icon="['fab','instagram']" fixed-width/></a></li>
            <li>
                <router-link :to="links.post_fr" :class="{strong: $route.params.lang=='fr'}">FR</router-link> | 
                <router-link :to="links.post_en" :class="{strong: $route.params.lang=='en'}">EN</router-link>
            </li>
            <li>
                <router-link :to="links.legal">{{$route.params.lang=='fr'?'Mentions légales':'Legal'}}</router-link>
            </li>
            <li>
                <router-link :to="links.privacy">{{$route.params.lang=='fr'?'Politique de confidentialité':'Privacy policy'}}</router-link>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
.blured-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 998;
}
.burger-menu {
    padding: 0 0.5rem;
        svg {
        height: 100%;
    }
}
.deployed-burger-menu {
    position: absolute;
    top: 0;
    left: -100%;
    width: 80vw;
    height: 100%;
    padding: 1.5rem;
    background-color: rgba(0,0,0,0.95);
    z-index: 999;
    transition-property: left;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin: 1.5rem 0;
    }
}
.burger-footer{
    position: absolute;
    bottom: 0;
}
a {
    text-decoration: none;
}
ul.section-list {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 900;
    ul {
        font-size: 1rem;
        font-weight: 400;
        li {
            padding-left: 1rem;
        }
    }
}
.socials {
    font-size: 2rem;
}
.deployed {
    left: 0;
}
.dark a.router-link-active {
    color: #8ebfd4;
}
</style>

<script setup>
import { ref,reactive } from 'vue'
//import { watch } from 'vue'
import { useRoute } from 'vue-router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { onClickOutside } from '@vueuse/core'
//import { usePage } from '@/stores/page_state'

//const axios = require('axios');

library.add(faBars, faWindowClose, faInstagram)

const route = useRoute()

const menuDeployed = ref(false)
const menuRef = ref(null)
const burgerRef = ref(null)
//const pageStore = usePage()

const links = reactive({
    legal: {name: 'legal', params: {lang: route.params.lang}},
    privacy: {name: 'privacy', params: {lang: route.params.lang}},
    post_fr: {name: 'home', params: {lang: 'fr'}},
    post_en: {name: 'home', params: {lang: 'en'}}
    })


onClickOutside(menuRef, () => {
    if(menuDeployed.value ) {
        menuDeployed.value = false
    }
})

function toggleMenu() {
        menuDeployed.value = menuDeployed.value? false : true
}

// watch(
//     () => pageStore.currentPage,
//     (newPage) => {
        
//         // what collection type is it ?
//         let theType
//         switch(route.name){
//         case "galerie_detail":
//             theType = "posts"
//             break
//         case "radio_detail":
//             theType = "radio"
//             break
//         }

//         // what language are we seeing right now ?

//         const VUE_APP_API_BASE_URL=process.env.VUE_APP_API_BASE_URL // "http://dev.la-sira.fr/wp-json/wp/v2"
//         let fullURL = VUE_APP_API_BASE_URL + '/' + theType + '/' + (newPage.lang == "fr" ? newPage.translations.en : newPage.translations.fr)
//         console.debug(fullURL)
//         axios.get( fullURL )
//         .then(
//             (response) => {
//                 if(route.params.lang == 'fr') {
//                     links.post_fr = {name: route.name, params: {lang: 'fr', id: newPage.slug}}
//                     links.post_en = {name: route.name, params: {lang: 'en', id: response.data.slug}}
//                 } else {
//                     links.post_en = {name: route.name, params: {lang: 'en', id: newPage.slug}}
//                     links.post_fr = {name: route.name, params: {lang: 'fr', id: response.data.slug}}
//                 }
//             }
//         ).catch(
//             (e) => {
//                 console.error(e)
//             }
//         )
//     }
// )

</script>