<template>
    <div id="fsimg" class="fullscreen-img" v-show="fs.display" ref="overlay">
        <img ref="refImage" :v-if="page.mediaCollection[fs.index]" :src="page.mediaCollection[fs.index].source_url" loading="lazy" :srcset="page.mediaCollection[fs.index].srcset">
        <a class="close-button" @click="hideOverlay"><FontAwesomeIcon icon="circle-xmark" size="sm"/></a>
        <a class="prev-button" v-show="fs.index>0" @click="prevImg"><FontAwesomeIcon icon="chevron-left" size="4x"/></a>
        <a class="next-button" v-show="fs.index<page.mediaCollection.length-1" @click="nextImg"><FontAwesomeIcon icon="chevron-right" size="4x"/></a>
        <div v-if="fs.caption!==null" class="caption">
            <a class="zoom-button" @click="toggleZoom"><FontAwesomeIcon icon="chevron-up" size="sm"/></a>
            <p class="title">{{ fs.caption }}</p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.fullscreen-img {
    position: absolute;
    z-index: 10;
    width: calc(100% - 1rem);
    height: 100%;
    //display: none;
    background: rgba(0,0,0,0.50);
    backdrop-filter: blur(6px);
    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto;
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        box-shadow: 0 0 1rem 1rem black;
    }
    .caption {
        position: absolute;
        top: calc(100vh - 3rem);
        .title {
            background: #000;
        }
        color: #fff;
        font-weight: bold;
        height: 7rem;
        width: 100%;
        transition: 500ms;
    }
    .title {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        padding: 0.5rem 0;
    }
    .zoom-button, .close-button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 32px;
        height: 32px;
        display: block;
        font-size: 2rem;
        transition: 500ms;
        color: #fff;
    }
    .prev-button, .next-button {
        position: absolute;
        top: 50%;
        margin-top: -4rem;
        // width: 8rem;
        // height: 8rem;
        display: block;
        // font-size: 2rem;
        transition: 500ms;
        color: #fff;
        opacity: 0.5;
    }
    .prev-button {
        left: 1rem;
    }
    .next-button {
        right: 1rem;
    }
    @media only screen and (min-width: 1024px) {
        .zoom-button {
            display: none;
        }
        .caption {
            top: calc(100vh - 6rem);
        }
        .title {
            padding: 1rem 0;
            opacity: 0;
            transition: 500ms;
        }
        .title:hover {
            opacity: 1;
        }
        .prev-button, .next-button {
            opacity: 1;
        }
    }
}
.fullscreen-img.zoomed {
    .caption {
            top: calc(100vh - 6.5rem);

    }
    .zoom-button{
        rotate: 180deg;
    }
}


</style>
<script setup>
import { ref } from 'vue'
import { useSwipe } from '@vueuse/core'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronUp, faChevronLeft, faChevronRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add([faChevronUp,faChevronLeft,faChevronRight,faCircleXmark])

import { useFullScreen } from '@/stores/fullscreen_state'
import { usePage } from '@/stores/page_state'

// eslint-disable-next-line no-undef
const overlay = ref(null)
const fs = useFullScreen()
const page = usePage()
var swipeOffset = 0

function hideOverlay() {
    fs.display=false
}
function hideOnEscape(e) {
    if (fs.display == true) {
        switch(e.key){
            case "Escape" :
                hideOverlay()
                //TODO maybe remove the "zoomed" class on #fsimg
                break
            case "ArrowLeft" :
                prevImg()
                break
            case "ArrowRight" :
                nextImg()
                break
        }
        e.preventDefault()
    }
}
const zoomed = ref(false)

const refImage = ref(null)
const { direction, lengthX } = useSwipe(
    refImage, { // your ref element 
        onSwipe() {
            swipeOffset = lengthX.value
            if(direction.value==="LEFT"&&fs.index<page.mediaCollection.length-1||
            direction.value==="RIGHT"&&fs.index>0){
                refImage.value.style.left = -swipeOffset + 'px'
                refImage.value.style.right = swipeOffset + 'px'
            }
        },
        onSwipeEnd() {
            //console.log( 'final offset : ' + swipeOffset )
            if (swipeOffset > 40) nextImg()
            else if (swipeOffset < -40 ) prevImg()
            swipeOffset = 0
            refImage.value.style.left = swipeOffset
            refImage.value.style.right = swipeOffset
        }
    }
)

function nextImg() {
    if (fs.index < page.mediaCollection.length - 1) fs.index++
}

function prevImg() {
    if (fs.index > 0) fs.index--
}

function toggleZoom (){
  if(!zoomed.value){
    document.getElementById("fsimg").classList.add("zoomed")
    zoomed.value = true
  } else {
    //document.getElementById("app").style.setProperty("grid-template-rows", "3rem 1fr 8rem")
    document.getElementById("fsimg").classList.remove("zoomed")
    zoomed.value = false
  }
}
document.body.addEventListener('keydown', hideOnEscape);
</script>