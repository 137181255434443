const axios = require('axios').default;
import { usePage } from '@/stores/page_state'
import { useNav } from '@/stores/nav_store'
const VUE_APP_API_BASE_URL=process.env.VUE_APP_API_BASE_URL // "http://dev.la-sira.fr/wp-json/wp/v2"
const sirapi = {
    getPageById: (id) => {
        axios.get(VUE_APP_API_BASE_URL + '/main_page/' + String(id))
        .then((response) => {
            let pageStore = usePage()
            pageStore.currentPage = response.data
            })
            .catch((error) => {
                console.error(error)
            })
    },
    getPageBySlug: (slug) => {
        axios.get(VUE_APP_API_BASE_URL + '/main_page?slug=' + slug)
            .then((response) => {
                let pageStore = usePage()
                pageStore.currentPage = response.data[0]
            })
            .catch((error) => {
                console.error(error)
            })
    },
    getPostBySlug: (slug) => {
        axios.get(VUE_APP_API_BASE_URL + '/posts?slug=' + slug)
            .then((response) => {
                let pageStore = usePage()
                pageStore.currentPage = response.data[0]
            })
            .catch((error) => {
                console.error(error)
            })
    },
    getRadioBySlug: (slug) => {
        axios.get(VUE_APP_API_BASE_URL + '/radio?slug=' + slug)
            .then((response) => {
                let pageStore = usePage()
                pageStore.currentPage = response.data[0]
            })
            .catch((error) => {
                console.error(error)
            })
    },
    getGaleryById: (id) => {
        let prom = axios.get(VUE_APP_API_BASE_URL + '/' + 'posts' + '/' + id)
        .then( async (response) =>
            { let theMedia = await sirapi.getMedia(response.data.featured_media)
               return {...response.data, featured_image: theMedia.data}
            }
        )
        .catch((error) => {
            console.error(error)
        })
        return prom
    },
    getPageChildren: (id) => {
        let pageStore = usePage()
        pageStore.postCollection = []
        let navStore = useNav()
        //navStore.subItems = []
        axios.get(
            VUE_APP_API_BASE_URL + 
            '/main_page/?parent=' + String(id) + 
            '&orderby=menu_order&order=asc' + 
            '&per_page=100' +
            '&_fields=id,title,slug,acf,type,parent,featured_media')
            .then(async (response) => {

                navStore.subItems = response.data
                //console.log("navStore")
                //console.log(response.data)
                let data = response.data.map(async (item) => {
                        if(item.featured_media){
                            let theMedia = await sirapi.getMedia(item.featured_media)
                            return {...item, featured_image: theMedia.data}
                        } else {
                            return { ...item, featured_image: null }
                        }
                })
                pageStore.postCollection = await Promise.all(data)
            })
            .catch((error) => {
                console.error(error)
            })
    },
    getMedia: (id) => {
        return axios.get(VUE_APP_API_BASE_URL + '/media/' + String(id) + '?_fields=id,source_url,caption,description,media_details,alt_text,media_type,mime_type')
    },
    getMedias: (ids) => {
        let pageStore = usePage()
        pageStore.mediaCollection = []
        //if(ids){
            Promise.all(ids.map( id => sirapi.getMedia(id)))
                .then((responses) => {
                    let data = responses.map(el => el.data)
                    pageStore.mediaCollection = data
                    
                })
                .catch(error => {
                    console.error("error in getMedia")
                    console.error(error)
                }
                )
        // } else {
        //     pageStore.mediaCollection = []
        // }
            
    },
    getCollection: (type, lang='fr') => {
        let pageStore = usePage()
        let navStore = useNav()
        pageStore.postCollection = []
        axios.get(VUE_APP_API_BASE_URL + 
            '/' + type + 
            '/?orderby=date' + 
            '&_fields=id,date,title,acf,slug,type,featured_media' + 
            '&per_page=100' +
            '&lang=' + lang )
            .then(async (response) =>
                {
                    let data = response.data.map(async (item) => {
                        let theMedia = null
                        if(item.featured_media) {
                            theMedia = await sirapi.getMedia(item.featured_media)
                            return {...item, featured_image: theMedia.data}
                        } else {
                            theMedia = null
                            return {...item, featured_image: null}
                        }
                    })
                    
                    pageStore.postCollection = await Promise.all(data)
                    let subLinks =  response.data.map((el) => (
                        {
                            id: el.id,
                            title: el.title,
                            slug: el.slug}))
                    navStore.subItems = subLinks
                    
                    
                }
            )
            .catch((error) => {
                console.error(error)
                pageStore.postCollection = []
                navStore.subItems = []
            })
        
    },
    getCollectionP: (type, lang='fr') => {
        return axios.get(
            VUE_APP_API_BASE_URL + '/' + type + 
            '/?orderby=date' +
            '&_fields=id,date,title,acf,slug,type,featured_media' +
            '&per_page=100' +
            '&lang=' + lang)
            .then( (response) =>
                {
                    let data = response.data.map((item) => {
                        if(item.featured_media) {
                            return sirapi.getMedia(item.featured_media)
                            .then( (theMedia) => {
                                return {...item, featured_image: theMedia.data}
                            })
                        } else {
                            return new Promise(res => {res({...item, featured_image: null})})
                        }
                    })
                    return  Promise.all(data)
                }
            )
            .catch((error) => {
                console.error(error)
            })
    },
    getMenu: (ids, lang='fr') => {
        axios.get(VUE_APP_API_BASE_URL + '/pages /?include=' + ids.join(',') + 'orderby=date&_fields=id,date,title,acf,slug,type,featured_media&lang=' + lang)
            .then((responses) => {
                return(responses.map( item => ({
                    slug: item.slug,
                    
                })))
            })
    },
    getParentPrefix: (id) => {
        let ret = "";
        switch(id) {
            case 681:
            case 686:
                ret = "consulting"
                break
            case 178:
            case 181:
            default:
                ret = ""
                break
            case 47:
            case 198:
                ret = "studio"
                break
        }
        return ret
    }
}


export default sirapi