import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useFullScreen = defineStore('fullscreenstate',{
    state: () => {
        return {
            image: {}, // holds the
            display: false,
            caption: "",
            description: "",
            index: 0,
            prev: {},
            next: {}
        }
    }
  // other options...
})