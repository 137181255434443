<template>
    <div class="page-block player">
        <span @click="playMedia" v-if="isStopped" class="control">Play</span>
        <span @click="pauseMedia" v-if="isPlaying||isStreaming" class="control">Pause</span>&nbsp;
        <span v-if="isFetching" class="position">...</span>
        <span v-if="isPlaying||isPaused" class="position">{{ prettyTime(playerStore.currentTime.toFixed(0)) }}/{{ prettyTime(playerStore.duration.toFixed(0)) }}</span>
        <span v-if="isStreaming" class="position">Live</span>
    </div>
</template>

<style lang="scss">
.player {
    font-size: 2rem;
    line-height: 0.9em;
    font-weight: 700;
    text-transform: uppercase;
    .control {
        color: #8ebfd4;
        cursor: pointer;
    }
    .position {
        color: #000;
    }
}
</style>
<script setup>
import { computed,watch } from 'vue'
import { usePlayer } from '@/stores/player_state'

const myAudio = document.createElement('audio');
const playerStore = usePlayer()
const isStopped = computed(() => (["paused","paused-yt","paused-stream","stopped"].includes(playerStore.currentState)))
const isFetching = computed(() => (playerStore.currentState=="fetching"))
const isPlaying = computed(() => (["playing","playing-yt"].includes(playerStore.currentState)))
const isStreaming = computed(() => (["playing-stream"].includes(playerStore.currentState)))
const isPaused = computed(() => (["paused","paused-yt","paused-stream"].includes(playerStore.currentState)))

function playMedia() {
    if(playerStore.currentState == "stopped") myAudio.setAttribute('src',playerStore.currentMediaURL)
    switch(playerStore.currentState){
        case "paused":
        case "paused-stream":
        case "stopped":
            playerStore.currentState = "fetching"
            myAudio.play()
            break
        case "paused-yt":
            playerStore.currentState = "playing-yt"
            if(playerStore.ytPlayer) playerStore.ytPlayer.playVideo()
            break;
    }
}

function pauseMedia() {
    switch(playerStore.currentState){
        case "playing":
            if(myAudio.duration == Infinity){
                playerStore.currentState = "stopped"
                myAudio.removeAttribute('src')
                myAudio.load()
            } else {
                playerStore.currentState = "paused"
                myAudio.pause()
            }
            break
        case "playing-stream":
            playerStore.currentState = "stopped"
            myAudio.removeAttribute('src')
            myAudio.load()
            break
        case "playing-yt":
            if(playerStore.ytPlayer) playerStore.ytPlayer.pauseVideo()
    }
}

function prettyTime(seconds) {
    let minutes = seconds / 60
    let hours = minutes / 60
    return(String(Math.floor(hours))+":"+String(Math.floor(minutes%60)).padStart(2,'0')+":"+String(Math.floor(seconds%60)).padStart(2,'0'))
}

// eslint-disable-next-line no-unused-vars
const timer = setInterval(() => {
    switch(playerStore.currentState) {
        case "playing":
            // eslint-disable-next-line vue/no-ref-as-operand
            playerStore.duration = myAudio.duration
            // eslint-disable-next-line vue/no-ref-as-operand
            playerStore.currentTime = myAudio.currentTime
            // if(playerStore.currentState == "playing" && myAudio.duration == Infinity) playerStore.currentState = "playing-stream"
            break;
        case "fetching":
            if (myAudio.duration == Infinity) {
                playerStore.currentState = "playing-stream"
            } else if (myAudio.duration.isNaN) {
                playerStore.currentState = "playing"
            }
            break;
    }
},500)

watch(
    () => playerStore.currentMediaURL,
    (newURL) => {
        myAudio.setAttribute('src',newURL)
        playerStore.currentState = "fetching"
        myAudio.play()
    }
)



</script>