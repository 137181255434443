import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useNav = defineStore('navStore',{
    state: () => {
        return {
            subItems: [], // holds the sub page information to be displayed on all blocks
        }
    }
  // other options...
})