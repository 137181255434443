import { trackRouter } from 'vue-gtag-next'
import { createRouter, createWebHistory } from 'vue-router'

//import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/:lang*',
    name: 'home_hub',
    //component: HomeView
    component: () => import(/* webpackChunkName: "sira" */ '../views/PassTrough.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "sira" */ '../views/LandingPage.vue')
      },
      {
        path: ':id',
        name: 'home_detail',
        component: () => import(/* webpackChunkName: "sira" */ '../views/MainPage.vue')
      },
    ]
  },
  {
    path: '/:lang*/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "sira" */ '../views/MainPage.vue')
  },
  {
    path: '/:lang*/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "sira" */ '../views/MainPage.vue')
  },
  {
    path: '/:lang*/studio',
    component: () => import(/* webpackChunkName: "studio" */ '../views/PassTrough.vue'),
    children: [
      {
        path: '',
        name: 'studio_hub',
        component: () => import(/* webpackChunkName: "studio" */ '../views/SubPagesList.vue'),
      },
      {
        path: ':id',
        name: 'studio_detail',
        component: () => import(/* webpackChunkName: "studio" */ '../views/MainPage.vue')
      },
    ]
  },
  {
    path: '/:lang*/consulting',
    component: () => import(/* webpackChunkName: "consulting" */ '../views/PassTrough.vue'),
    children: [
      {
        path: ':id',
        name: 'consulting_detail',
        component: () => import(/* webpackChunkName: "consulting" */ '../views/MainPage.vue')
      },
      {
        path: '',
        name: 'consulting_hub',
        component: () => import(/* webpackChunkName: "consulting" */ '../views/SubPagesList.vue'),
      },
    ]
  },
  {
    path: '/:lang*/galerie',
    name: 'galerie_section',
    component: () => import(/* webpackChunkName: "consulting" */ '../views/PassTrough.vue'),
    children: [
      {
        path: '',
        name: 'galerie_hub',
        component: () => import(/* webpackChunkName: "galerie" */ '../views/CollectionList.vue')
      },
      {
        path: ':id',
        name: 'galerie_detail',
        component: () => import(/* webpackChunkName: "galerie" */ '../views/CollectionDetail.vue')
      },
    ]
  },
  {
    path: '/:lang*/radio',
    name: 'radio_section',
    component: () => import(/* webpackChunkName: "consulting" */ '../views/PassTrough.vue'),
    children: [
      {
        path: '',
        name: 'radio_hub',
        component: () => import(/* webpackChunkName: "radio" */ '../views/CollectionList.vue')
      },
      {
        path: ':id',
        name: 'radio_detail',
        component: () => import(/* webpackChunkName: "radio" */ '../views/CollectionDetail.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router)

export default router
