<template>
        <router-link :to="links.post_fr" :class="{strong: $route.params.lang=='fr'}">FR</router-link><span> | </span>
        <router-link :to="links.post_en" :class="{strong: $route.params.lang=='en'}">EN</router-link><span> | </span>
        <router-link :to="links.legal">{{$route.params.lang=='fr'?'Mentions légales':'Legal'}}</router-link><span> | </span>
        <router-link :to="links.privacy">{{$route.params.lang=='fr'?'Politique de confidentialité':'Privacy policy'}}</router-link>
</template>

<style scoped>
.footer a, .footer span{
    font-size: 0.8rem;
}
.strong {
    font-weight: bold;
}
</style>
<script setup>
import { useRoute } from 'vue-router'
import { watch,reactive } from 'vue'
import { usePage } from '@/stores/page_state'

const axios = require('axios');

const route = useRoute()
const pageStore = usePage()

const links = reactive({
    legal: {name: 'legal', params: {lang: "fr"}},
    privacy: {name: 'privacy', params: {lang: "fr"}},
    post_fr: {name: 'home', params: {lang: 'fr'}},
    post_en: {name: 'home', params: {lang: 'en'}}
    })
watch(
    () => route.path,
    () => {
        switch(route.name){
            case 'home':
            case 'legal':
            case 'privacy':
            case 'home_hub':
            case 'studio_hub':
            case 'studio-photo':
            case 'studio-atelier':
            case 'consulting_hub':
            case 'galerie_hub':
            case 'radio_hub':
                links.post_fr = {name: route.name, params: {lang: 'fr'}}
                links.post_en = {name: route.name, params: {lang: 'en'}}
                break
        }

    }
)

watch(
    () => pageStore.currentPage,
    (newPage) => {
        
        // what collection type is it ?
        let theType
        switch(route.name){
        case "galerie_detail":
            theType = "posts"
            break
        case "radio_detail":
            theType = "radio"
            break
        case "studio_detail":
        case "consulting_detail":
        case 'home_detail':
            theType = "main_page"
        }

        // what language are we seeing right now ?

        const VUE_APP_API_BASE_URL=process.env.VUE_APP_API_BASE_URL // "http://dev.la-sira.fr/wp-json/wp/v2"
        let fullURL = VUE_APP_API_BASE_URL + '/' + theType + '/' + (newPage.lang == "fr" ? newPage.translations.en : newPage.translations.fr)
        // console.debug(fullURL)
        axios.get( fullURL )
        .then(
            (response) => {
                if(route.params.lang == 'fr') {
                    links.post_fr = {name: route.name, params: {lang: 'fr', id: newPage.slug}}
                    links.post_en = {name: route.name, params: {lang: 'en', id: response.data.slug}}
                } else {
                    links.post_en = {name: route.name, params: {lang: 'en', id: newPage.slug}}
                    links.post_fr = {name: route.name, params: {lang: 'fr', id: response.data.slug}}
                }
            }
        ).catch(
            (e) => {
                console.debug(e)
            }
        )
    }
)
</script>